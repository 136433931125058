import styled from "styled-components";
import { colors } from "../colors";

type ButtonProps = {
  children: JSX.Element | string;
  onClick?: () => void;
};

const ButtonStyled = styled.button`
  background: linear-gradient(35deg, #006b7f, #00938e);
  border: none;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  margin: 0.75em;
  min-width: 150px;
  padding: 0.8em 2em;
`;

export const Button = ({ children, onClick }: ButtonProps) => {
  return <ButtonStyled onClick={onClick}>{children}</ButtonStyled>;
};
