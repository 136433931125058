import styled from "styled-components";
import { colors } from "../../../colors";

type OfferType = {
  id: number;
  company: string;
  location: string;
  salary: string;
  title: string;
};

type OfferProps = {
  offer: OfferType;
};

const OfferContainer = styled.div`
  border: 1px ${colors.primary} solid;
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 60px 1fr 1fr;
  margin-bottom: 1em;
  min-height: 100px;
  padding: 1em;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  }
`;
const CompanyLogo = styled.img`
  float: left;
  max-height: 45px;
  max-width: 45px;
`;
const OfferTitle = styled.h2`
  margin: 0.1em 0;
`;
const OfferSubTitle = styled.h3`
  font-size: 0.8em;
  font-weight: 300;
  margin: 0;
`;
const OfferSalary = styled.p`
  font-size: 0.8em;
  font-weight: 300;
  margin: 0;
`;
const OfferTags = styled.div``;
const OfferLocation = styled.p`
  margin: 0;
`;

export const Offer = ({ offer }: OfferProps) => {
  return (
    <OfferContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CompanyLogo src={`/img/${offer.id}.png`} />
      </div>
      <div>
        <OfferSubTitle>{offer.company}</OfferSubTitle>
        <OfferTitle>{offer.title}</OfferTitle>
        <OfferSalary>{offer.salary} PLN</OfferSalary>
      </div>
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <OfferLocation>{offer.location}</OfferLocation>
        <OfferTags>Tagi</OfferTags>
      </div>
    </OfferContainer>
  );
};
