import styled from "styled-components";

export const SearchHeader = styled.header`
  display: grid;
  grid-template-columns: 350px 1fr 350px;
  grid-template-areas: "title search login";
  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title login"
      "search search";
  }
`;
export const OffersContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 805px;
  margin: 0 auto;
  padding: 0.75em;
`;

export const AddOffer = styled.span`
  margin-right: 2em;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  grid-area: search;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
