import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Container, Header, MainContainer } from "./Main.styled";
import { SiteTitle } from "../../styled/shared";
import { FormEvent } from "react";

export const Main = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const handleSearchClick = (e: FormEvent) => {
    e.preventDefault();
    search && navigate(`/szukaj/${search}`);
  };
  const handleLoginClick = () => navigate(`/logowanie`);
  return (
    <MainContainer>
      <Header>
        <span style={{ marginRight: "2em" }}>+ Dodaj ogłoszenie</span>
        <Button onClick={handleLoginClick}>Zaloguj</Button>
      </Header>
      <Container onSubmit={handleSearchClick}>
        <SiteTitle>
          Job<b>Board</b>
        </SiteTitle>
        <Input
          type="text"
          placeholder="Jakiej pracy szukasz?"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          value={search}
          width="600px"
        />
        <Button>Szukaj</Button>
      </Container>
      <footer style={{ textAlign: "center" }}>
        &copy; 2021 by Dawid Zamkowski
        <br />
        coding by Tomasz Sędor
      </footer>
    </MainContainer>
  );
};
