import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { SiteTitle } from "../../styled/shared";
import {
  AddOffer,
  OffersContainer,
  SearchBarContainer,
  SearchHeader,
} from "./Search.styled";
import { getOffersByName } from "../../services/offers";
import { Offer } from "./components/Offer";
import { Loader } from "../../components/Loader";

type OfferType = {
  id: number;
  company: string;
  location: string;
  salary: string;
  title: string;
};

export const Search = () => {
  const navigate = useNavigate();
  const { query } = useParams();
  const [search, setSearch] = useState(query);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = query && (await getOffersByName(query));
        setOffers(response);
      } catch (e) {
        setOffers([]);
        //alert("blad");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [query]);

  const handleSearchClick = () => {
    search && navigate(`/szukaj/${search}`);
  };
  const handleLoginClick = () => navigate(`/logowanie`);
  return (
    <>
      <SearchHeader>
        <SiteTitle>
          Job<b>Board</b>
        </SiteTitle>
        <SearchBarContainer>
          <Input
            type="text"
            placeholder="Jakiej pracy szukasz?"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            value={search}
          />
          <Button onClick={handleSearchClick}>Szukaj</Button>
        </SearchBarContainer>
        <div
          style={{
            gridArea: "login",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <AddOffer>Dodaj ogłoszenie</AddOffer>
          <Button onClick={handleLoginClick}>Zaloguj</Button>
        </div>
      </SearchHeader>
      <OffersContainer>
        {loading && <Loader />}
        {offers.map((offer: OfferType) => (
          <Offer offer={offer} key={offer.id} />
        ))}
      </OffersContainer>
    </>
  );
};
