import { ChangeEvent, FormEvent, useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { LoginContainer, LoginForm } from "./Login.styled";
import { SiteTitle } from "../../styled/shared";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(e, email, password);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  return (
    <LoginContainer>
      <div>
        <SiteTitle>
          Job<b>Board</b>
        </SiteTitle>
        <LoginForm onSubmit={handleSubmit}>
          <Input
            label="email"
            type="email"
            onChange={handleEmailChange}
            value={email}
          />
          <Input
            label="hasło"
            type="password"
            onChange={handlePasswordChange}
            value={password}
          />
          <Button>Zaloguj się</Button>
        </LoginForm>
      </div>
    </LoginContainer>
  );
};
