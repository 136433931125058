import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Main } from "./views/Main/Main";
import { Provider } from "react-redux";
import { store } from "./store";
import { Search } from "./views/Search/Search";
import "normalize.css";
import { Login } from "./views/Login/Login";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    color: ${colors.text};
    font-family: 'Open Sans', sans-serif;
  }
  html, body, #root {
    height: 99%;
  }
`;

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/szukaj/:query" element={<Search />} />
            <Route path="/logowanie" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
};
