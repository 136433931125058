import { ChangeEvent } from "react";
import styled from "styled-components";
import { colors } from "../colors";

type InputProps = {
  label?: string;
  placeholder?: string;
  type?: "text" | "password" | "number" | "email";
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  value?: string;
};

type InputStyledProps = {
  manwidth?: string;
};

const Label = styled.label`
  font-size: 0.8em;
  font-weight: 300;
  text-align: center;
  text-transform: capitalize;
`;

const InputStyled = styled.input<InputStyledProps>`
  border: 1px ${colors.primary} solid;
  border-radius: 8px;
  margin: 0.75em;
  padding: 0.75em 2em;
  width: 100%;
  width: ${({ manwidth }) => manwidth && manwidth};
  @media (max-width: 800px) {
    width: initial;
  }
`;

export const Input = ({
  label,
  onChange,
  placeholder,
  type,
  width,
  value,
}: InputProps) => {
  return (
    <>
      <Label>{label}</Label>
      <InputStyled
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        manwidth={width}
      />
    </>
  );
};
