import styled, { keyframes } from "styled-components";
import { colors } from "../colors";

const waveAnimation = keyframes`
0%, 100% {
  transform: translateY(0)
}
50% {transform: translateY(-15px); background-color: #00938E}`;
const LoaderContainer = styled.div`
  margin: 2em auto;
  div:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  div:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  div:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  div:nth-of-type(5) {
    animation-delay: 0.4s;
  }
`;
const Dot = styled.div`
  animation: ${waveAnimation} 1s ease-in-out infinite;
  background-color: ${colors.primary};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 4px;
  width: 10px;
`;

export const Loader = () => {
  return (
    <LoaderContainer>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </LoaderContainer>
  );
};
