import styled from "styled-components";

export const LoginContainer = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin: 0;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  max-width: 300px;
`;
