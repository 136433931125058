import styled from "styled-components";

export const MainContainer = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
`;
export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export const Container = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
`;
