import { useNavigate } from "react-router";
import styled from "styled-components";

import { colors } from "../colors";

export const Title = styled.h1`
  align-items: center;
  color: ${colors.text};
  cursor: pointer;
  display: flex;
  font-family: "Exo 2", sans-serif;
  font-size: 3em;
  font-weight: 500;
  grid-area: title;
  margin: 0 0.5em;
  text-align: left;
  b {
    font-weight: 800;
  }
  @media (max-width: 800px) {
    font-size: 2em;
  }
`;

export const SiteTitle = ({
  children,
}: {
  children: string | React.ReactNode;
}) => {
  const navigate = useNavigate();
  const handleClickTitle = () => navigate("/");
  return <Title onClick={handleClickTitle}>{children}</Title>;
};
